import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'
import { filterByValue } from '../utilities/helperFunctions'

const swal = withReactContent(Swal)

/* * initial State */
const initialState = {
  isNew: false,
  registriesSiteId: null,
  sites: [],
  orgSites: [],
  selectedSiteId: null,
  site: {},
  sitePending: {},
  adUnits: [],
  adUnitDictionary: [],
  validationAlert: {},
  siteIVR: {
    DEFAULT_REFRESH_COUNT: 0,
    DEFAULT_REFRESH_INTERVAL: 0,
    DEFAULT_DISPLAY_PERCENT: 0
  },
  siteRTAI: {
    MAX_NO_OF_ADS: 0,
    BUFFER: 0,
    OFFSET: 0,
    INITIAL_PLACEMENT: 0,
    PARENT_SELECTOR: ''
  },
  next: () => {},
  updatingSiteGeneralInfo: false,
  updatingSiteCategories: false,
  updatingSiteConfiguration: false,
  updatingSiteAdFuel: false,
  updatingSiteAdFuelModules: false,
  updatingSiteResponsiveness: false,
  loading: false,
  isAdFuelVersionSelected: false
}

const sitesSlice = createSlice({
  name: 'sitesSlice',
  initialState,
  reducers: {
    FetchRocketeerSites: (state, action) => {
      state.loading = false
      state.sites = action.payload
      state.orgSites = action.payload
    },

    FetchSiteIVRSettings: (state, action) => {
      state.loading = false
      state.siteIVR = {
        ...action.payload.IVR,
      }
    },

    FetchSiteRTAISettings: (state, action) => {
      state.loading = false
      state.siteRTAI = {
        ...action.payload.RTAI,
      }
    },

    FetchSiteById: (state, action) => {
      state.loading = false
      state.site = action.payload
    },

    AddCommentToHistory: (state, action) => {
      state.site.history = [
        action.payload.newHistoryItem,
        ...state.site.history
      ]
      state.loading = false
      swal.fire({
        title: 'History Added!',
        text: `${action.payload.newHistoryItem.newValue} added successfully.`,
        icon: 'success'
      })
    },

    setSite: (state, action) => {
      console.log('Payload', action.payload)
      if (!action.payload.reason) {
        state.site = action.payload
        swal.fire({
          title: 'Site Updated!',
          text: 'Site Updated.',
          icon: 'success'
        })
      } else {
        swal.fire({
          title: 'Oops!',
          text: 'Something went wrong.',
          icon: 'error'
        })
      }
    },

    CreateSite: (state, action) => {
      const orgSites = state.orgSites.slice()
      const sites = state.sites.slice()
      sites.unshift(action.payload)
      orgSites.unshift(action.payload)
      state.loading = false
      state.sites = sites
      state.orgSites = orgSites
      swal.fire({
        title: 'Site Saved!',
        text: 'Site saved successfully.',
        icon: 'success'
      })
      state.isNew = false
    },

    ToggleIsNewStatus: state => {
      state.isNew = !state.isNew
    },

    FilterSite: (state, action) => {
      const results = filterByValue(state.orgSites, action.payload)
      state.loading = false
      state.sites = results
    },

    SelectedItemId: (state, action) => {
      state.selectedItemId = action.payload
    },

    SiteLoadingStatus: state => {
      state.loading = true
    },

    SitesError: (state, action) => {
      state.loading = false
      state.error = action.payload.error
      handleError(action.payload, showError)
    },

    ResetSelectedSite: state => {
      state.registries = initialState.registries
      state.site = initialState.site
    },
    setValidationAlert: (state, action) => {
      state.validationAlert = action.payload.alert
      state.next = action.payload.next
    },
    resetValidationAlert: (state) => {
      state.validationAlert = {}
      state.next = () => {}
    },
    setUpdatingSiteGeneralInfo: (state, action) => {
      state.updatingSiteGeneralInfo = action.payload
    },
    setUpdatingSiteCategories: (state, action) => {
      state.updatingSiteCategories = action.payload
    },
    setUpdatingSiteAdFuel: (state, action) => {
      state.updatingSiteAdFuel = action.payload
    },
    setUpdatingSiteAdFuelModules: (state, action) => {
      state.updatingSiteAdFuelModules = action.payload
    },
    setUpdatingSiteResponsiveness: (state, action) => {
      state.updatingSiteResponsiveness = action.payload
    },
    setUpdatingSiteConfiguration: (state, action) => {
      state.updatingSiteConfiguration = action.payload
    },
    setSitePending: (state, action) => {
      state.sitePending = action.payload
    },
    ToggleIsAdFuelVersionSelected: (state, action) => {
      state.isAdFuelVersionSelected = action.payload
    }
  }
})

export default sitesSlice.reducer
export const {
  FetchRocketeerSites,
  FetchSiteById,
  FetchSiteByName,
  FetchSiteIVRSettings,
  FetchSiteRTAISettings,
  AddCommentToHistory,
  setSite,
  CreateSite,
  FilterSite,
  SelectedItemId,
  SiteLoadingStatus,
  ToggleIsNewStatus,
  SitesError,
  ResetSelectedSite,
  setValidationAlert,
  resetValidationAlert,
  setUpdatingSiteGeneralInfo,
  setUpdatingSiteCategories,
  setUpdatingSiteAdFuel,
  setUpdatingSiteAdFuelModules,
  setUpdatingSiteResponsiveness,
  setUpdatingSiteConfiguration,
  setSitePending,
  ToggleIsAdFuelVersionSelected
} = sitesSlice.actions

// #region Sites
export const fetchRocketeerSites = () => {
  return async dispatch => {
    dispatch(SiteLoadingStatus())
    return request('/siteNames')
      .then(res => {
        dispatch(FetchRocketeerSites(res.data))
        return res.data
      })
      .catch(err => dispatch(SitesError(err)))
  }
}

export const fetchSiteIVRConfiguration = (siteId) => {
  return async dispatch => {
    return request(`/lookups/getAdFuelModuleConfiguration?site=${siteId}&module=62fcec3f7b6899e0f0d1bcfb`)
      .then(res => {
        dispatch(FetchSiteIVRSettings(res.data))
        return res.data
      })
      .catch(err => dispatch(SitesError(err)))
  }
}

export const fetchSiteRTAIConfiguration = (siteId) => {
  return async dispatch => {
    return request(`/lookups/getAdFuelModuleCOnfiguration?site=${siteId}&module=63c6e8986a08725ee3aec5c1`)
      .then(res => {
        dispatch(FetchSiteRTAISettings(res.data))
        return res.data
      })
      .catch(err => dispatch(SitesError(err)))
  }
}

export const addSiteComment = (siteId, comment) => {
  return async dispatch => {
    dispatch(SiteLoadingStatus())
    return request(`/sites/comment/${siteId}`, 'POST', {
      comment
    })
      .then(res => {
        dispatch(AddCommentToHistory(res.data))
      })
      .catch(err => dispatch(SitesError(err)))
  }
}

export const fetchSiteById = siteId => {
  return async dispatch => {
    dispatch(SiteLoadingStatus())
    return request(`/sites/${siteId}`)
      .then(res => dispatch(FetchSiteById(res.data)))
      .catch(err => dispatch(SitesError(err)))
  }
}

export const fetchSiteByName = siteName => {
  return async dispatch => {
    dispatch(SiteLoadingStatus())
    console.log(`[sitesSlice] ---------- Fetching Site By Name::/site/${siteName}`)
    return request(`/site/${siteName}`)
      .then(res => dispatch(FetchSiteById(res.data)))
      .catch(err => dispatch(SitesError(err)))
  }
}

export const updateSite = Site => {
  return async dispatch => {
    return request(`/site/${Site._id}`, 'POST', Site)
      .then(res => {
        dispatch(setSite(res.data))
      })
      .catch(err => dispatch(SitesError(err)))
  }
}

export const updateSiteConfiguration = site => {
  dispatch(setUpdatingSiteConfiguration(true))
  dispatch(resetValidationAlert())
  return request('/sites/configuration', 'POST', site)
    .then(res => {
      dispatch(setUpdatingSiteConfiguration(false))
      if (res.data.validationWarnings) {
        dispatch(setSitePending(site))
        dispatch(setValidationAlert({ alert: res.data, next: updateSiteConfiguration }))
      } else {
        dispatch(setSite(res.data))
      }
    })
    .catch(err => {
      dispatch(setUpdatingSiteConfiguration(false))
      dispatch(SitesError(err))
    })
}

export const updateGeneralInformation = site => {
  return async dispatch => {
    dispatch(setUpdatingSiteGeneralInfo(true))
    dispatch(resetValidationAlert())
    return request('/sites/general', 'POST', site)
      .then(res => {
        dispatch(setUpdatingSiteGeneralInfo(false))
        if (res.data.validationWarnings) {
          dispatch(setSitePending(site))
          dispatch(setValidationAlert({ alert: res.data, next: updateGeneralInformation }))
        } else {
          dispatch(setSite(res.data))
        }
      })
      .catch(err => {
        dispatch(setUpdatingSiteGeneralInfo(false))
        dispatch(SitesError(err))
      })
  }
}

export const updateCategories = site => {
  return async dispatch => {
    dispatch(setUpdatingSiteCategories(true))
    return request('/sites/categories', 'POST', site)
      .then(res => {
        dispatch(setUpdatingSiteCategories(false))
        dispatch(setSite(res.data))
      })
      .catch(err => {
        dispatch(setUpdatingSiteCategories(false))
        dispatch(SitesError(err))
      })
  }
}

export const updateAdFuel = site => {
  // console.log('updateAdFuel...')
  return async dispatch => {
    dispatch(setUpdatingSiteAdFuel(true))
    dispatch(resetValidationAlert())
    return request('/sites/adfuel', 'POST', site)
      .then(res => {
        dispatch(setUpdatingSiteAdFuel(false))
        if (res.data.validationWarnings) {
          dispatch(setSitePending(site))
          dispatch(setValidationAlert({ alert: res.data, next: updateAdFuel }))
        } else {
          // console.log('res.data', res.data)
          dispatch(setSite(res.data))
        }
      })
      .catch(err => {
        dispatch(setUpdatingSiteAdFuel(false))
        dispatch(SitesError(err))
      })
  }
}

export const updateAdFuelAndModules = (site, adfuel, modules) => {
  // console.log('updateAdFuel...')
  return async dispatch => {
    dispatch(setUpdatingSiteAdFuel(true))
    dispatch(resetValidationAlert())
    if (adfuel && modules) {
      console.log('UAAM ----- Updating AdFuel and Modules.....')
      return request('/sites/adfuel', 'POST', site)
        .then(res => {
          if (res.data.validationWarnings) {
            dispatch(setSitePending(site))
            dispatch(setValidationAlert({ alert: res.data, next: updateAdFuelAndModules }))
          } else {
            console.log('UAAM ----- Saving AdFuel...', res.data)
            return request('/sites/adFuelModules', 'POST', res.data)
              .then(res => {
                console.log('UAAM ----- Received response...', res.data)
                dispatch(setUpdatingSiteAdFuel(false))
                console.log('UAAM ----- Success...')
                dispatch(setSite(res.data))
              })
              .catch(err => {
                console.error('updateAdFuelAndModules ===== ===== Error: ', err)
                dispatch(setUpdatingSiteAdFuel(false))
              })
          }
        })
        .catch(err => {
          dispatch(setUpdatingSiteAdFuel(false))
          dispatch(SitesError(err))
        })
    } else if (adfuel) {
      console.log('UAAM ----- Updating AdFuel.....')
      return request('/sites/adfuel', 'POST', site)
        .then(res => {
          dispatch(setUpdatingSiteAdFuel(false))
          if (res.data.validationWarnings) {
            dispatch(setSitePending(site))
            dispatch(setValidationAlert({ alert: res.data, next: updateAdFuelAndModules }))
          } else {
            // console.log('res.data', res.data)
            dispatch(setSite(res.data))
          }
        })
        .catch(err => {
          dispatch(setUpdatingSiteAdFuel(false))
          dispatch(SitesError(err))
        })
    } else if (modules) {
      console.log('UAAM ----- Updating Modules.....')
      console.log('UAAM ----- Setting updating flag to true...')
      dispatch(setUpdatingSiteAdFuelModules(true))
      console.log('UAAM ----- Resetting validation alert...')
      dispatch(resetValidationAlert())
      console.log('UAAM ----- Posting to API /sites/adFuelModules...')
      const response = await request('/sites/adFuelModules', 'POST', site)
        .then(res => {
          console.log('UAAM ----- Received response...', res)
          dispatch(setUpdatingSiteAdFuelModules(false))
          if (res.data.validationWarnings) {
            console.log('UAAM ----- Found validation warnings...')
            dispatch(setSitePending(site))
            dispatch(setValidationAlert({ alert: res.data, next: updateAdFuelAndModules }))
          } else if (res.data.reason) {
            console.log('UAAM ----- Did not get a valid response...')
            dispatch(setUpdatingSiteAdFuelModules(false))
            dispatch(SitesError(res.data.reason))          
          } else {
            console.log('UAAM ----- Success...')
            dispatch(setSite(res.data))
          }
        })
        .catch(err => {
          console.log('UAAM ----- Error: ', err)
          dispatch(setUpdatingSiteAdFuelModules(false))
        })
      return response
    }
  }
}

export const createSite = Site => {
  return async dispatch => {
    return request('/sites/general', 'POST', Site)
      .then(res => dispatch(CreateSite(res.data)))
      .catch(err => dispatch(SitesError(err)))
  }
}

export const filterSite = searchTerm => {
  return async dispatch => {
    dispatch(FilterSite(searchTerm))
  }
}

export const updateSiteResponsiveness = (site) => {
  return async dispatch => {
    dispatch(setUpdatingSiteResponsiveness(true))
    dispatch(resetValidationAlert())
    return request('/sites/responsiveness', 'POST', site)
      .then(res => {
        dispatch(setUpdatingSiteResponsiveness(false))
        if (res.data.validationWarnings) {
          dispatch(setSitePending(site))
          dispatch(setValidationAlert({ alert: res.data, next: updateSiteResponsiveness }))
        } else {
          dispatch(setSite(res.data))
        }
      })
      .catch(err => {
        dispatch(setUpdatingSiteResponsiveness(false))
        console.log('err', err)
      })
  }
}

export const generateId = () => {
  return request('/id')
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const updateSiteAdFuelModules = (site) => {
  console.log('SITE', site)
  return async dispatch => {
    console.log('Setting updating flag to true...')
    dispatch(setUpdatingSiteAdFuelModules(true))
    console.log('Resetting validation alert...')
    dispatch(resetValidationAlert())
    console.log('Posting to API /sites/adFuelModules...')
    const response = await request('/sites/adFuelModules', 'POST', site)
      .then(res => {
        console.log('Received response...', res)
        dispatch(setUpdatingSiteAdFuelModules(false))
        if (res.data.validationWarnings) {
          console.log('Found validation warnings...')
          dispatch(setSitePending(site))
          dispatch(setValidationAlert({ alert: res.data, next: updateSiteAdFuelModules }))
        } else if (res.data.reason) {
          console.log('Did not get a valid response...')
          dispatch(setUpdatingSiteAdFuelModules(false))
          dispatch(SitesError(res.data.reason))
        } else if (res.data.concurrencyError) {
          console.log('Record has changed.', res.data.concurrencyError)
          dispatch(setUpdatingSiteAdFuelModules(false))
          dispatch(SitesError(res.data.concurrencyError))
        } else {
          console.log('Success...')
          dispatch(setSite(site))
        }
      })
      .catch(err => {
        console.log('Error: ', err)
        dispatch(setUpdatingSiteAdFuelModules(false))
      })
    return response
  }
}

export const clearSite = () => {
  return async dispatch => {
    dispatch(FetchSiteById({}))
  }
}

// #endregion
