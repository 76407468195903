import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearRegistries,
  deploySiteRegistries,
  CloseToast
} from '../../modules/registrySlice'
import {
  fetchRocketeerSites,
  updateGeneralInformation, ToggleIsAdFuelVersionSelected
} from '../../modules/sitesSlice'
import { fetchAllAdUnits } from '../../modules/DFPAdUnitSlice'
import { fetchAdFuelModules } from '../../modules/adFuelModuleSlice'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { fetchAdFuelVersions } from '../../modules/adFuelSlice'

import {
  Container,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Box,
  List,
  ListItem,
  CircularProgress,
  Breadcrumbs,
  Grid
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import {
  GeneralInformation,
  Categories,
  Responsiveness,
  AdFuelConfiguration,
  AISConfiguration,
  NPMConfiguration
} from './index'
import HistoryIcon from '@material-ui/icons/History'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
// import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'

import { clone, siteNameFormatter } from '../../utilities/helperFunctions'
import { addClasses, commonStyle } from '../../styles'
import { CustomDialog, Toast } from '../ui'

const swal = withReactContent(Swal)

export const Site = () => {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { site, sites, sitePending, validationAlert, next, isAdFuelVersionSelected } = useSelector(
    state => state.sitesSlice
  )
  const { registries, toast } = useSelector(state => state.registrySlice)
  const { adFuelVersions } = useSelector(state => state.adFuelSlice)
  const siteLoading = useSelector(state => state.sitesSlice.loading)
  const adRootLoading = useSelector(state => state.dfp.loading)
  const adRoots = useSelector(state => state.dfp.adRoots)
  const [rocketeerSite, setRocketeerSite] = useState(clone(site))
  const [openDialog, setOpenDialog] = useState(false)
  const [dialog, setDialog] = useState({ content: '' })
  const { canEditSite } = useSelector(state => state.authReducer)
  const { adFuelModules: allAdFuelModules } = useSelector(state => state.adFuelModulesSlice)
  const [loadingPage, setLoadingPage] = useState(true)
  const [implementationMethod, setImplementationMethod] = useState('CDN')

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(CloseToast())
  }

  const deployRegistries = () => {
    if (registries.length > 0) {
      swal.fire({
        title: 'Are you sure?',
        text:
          'Once deployed, the current registries in the database will overwrite the existing registries on the CDN for the site. This cannot be reversed.',
        icon: 'warning',
        showCancelButton: true
        // buttons: true,
        // dangerMode: true
      }).then(willDeploy => {
        if (willDeploy.isConfirmed) {
          dispatch(deploySiteRegistries(registries))
        }
      })
    } else {
      swal.fire({
        title: 'No registries!',
        text: 'No registries to Deploy.',
        icon: 'warning'
      })
    }
  }

  const toggleSiteActivation = () => {
    rocketeerSite.isActive = !rocketeerSite.isActive
    dispatch(updateGeneralInformation(rocketeerSite))
  }

  useEffect(() => {
    if (adFuelVersions.length === 0) {
      dispatch(fetchAdFuelVersions())
    } else {
      const adFuelVersion = adFuelVersions.find(adfuel => adfuel._id === site.adFuelVersionId)
      dispatch(ToggleIsAdFuelVersionSelected(adFuelVersion !== undefined))
    }
  }, [adFuelVersions])

  useEffect(() => {
    if (validationAlert.validationWarnings) {
      setDialog({
        size: 'sm',
        content: displayValidationWarnings(validationAlert, next),
        displayDialogAction: false
      })
      setOpenDialog(true)
    }
  }, [validationAlert])

  useEffect(() => {
    if (sites && sites.length <= 0) {
      dispatch(fetchRocketeerSites())
    }
    if (adRoots.length <= 0) {
      dispatch(fetchAllAdUnits())
    }
    if (allAdFuelModules.length <= 0) {
      dispatch(fetchAdFuelModules())
    }
  }, [])

  useEffect(() => {
    dispatch(clearRegistries())
    setTimeout(() => {
      setLoadingPage(false)
    }, 2000)
  }, [])

  useEffect(() => {
    if (Object.keys(site).length !== 0) {
      const _sites = clone(site)
      const { adFuelModules } = site
      const adFuelModuleList = adFuelModules?.filter(
        adFuelModule =>
          allAdFuelModules?.find(
            ({ _id }) => adFuelModule.adFuelModuleId === _id
          )
      )
      _sites.adFuelModules = adFuelModuleList
      _sites.usePackage = false
      setImplementationMethod(_sites.usePackage ? 'NPM' : 'CDN')
      setRocketeerSite(clone(_sites))
    }
  }, [site])

  const displayValidationWarnings = (validationAlert, next) => {
    const { registriesAffected, validationWarnings } = validationAlert
    return (
      <Card>
        <CardHeader title='Confirm saving site?' />
        <Divider />
        <CardContent>
          {sitePending.name} has changes affecting registries, requiring
          redeployment:
          <ul>
            {validationWarnings.map((item, index) => {
              return (
                <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
              )
            })}
          </ul>
          Are you sure you want to save your changes?
        </CardContent>
        <Divider />
        <CardActions className={commonClasses.actionArea}>
          <Button
            variant='text'
            onClick={() => {
              setOpenDialog(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className={commonClasses.btnSuccess}
            variant='contained'
            onClick={() => {
              const newSite = clone(sitePending)
              newSite.hasConfirmedValidationWarnings = true
              dispatch(next(newSite))
              if (registriesAffected && registriesAffected.length > 0) {
                // dispatch(fetchRegistriesForSite(site._id))
                swal.fire({
                  text: registriesAffected.length + ' registries updated'
                })
              }
              const adFuelVersion = adFuelVersions.find(adfuel => adfuel._id === newSite.adFuelVersionId)
              dispatch(ToggleIsAdFuelVersionSelected(adFuelVersion !== undefined))
              setOpenDialog(false)
            }}
          >
            Ok
          </Button>
        </CardActions>
      </Card>
    )
  }

  const workingText = () => {
    if (siteLoading) { 
      return 'Loading Site Information...'
    } else if (adRootLoading) {
      return 'Loading Ad Roots...'
    } else {
      return 'Reloading...'
    }
  }  
  
  return (
    <>
      {site._id && (
        <Breadcrumbs
          className={addClasses(commonClasses.pl_3, commonClasses.pb_1, loadingPage ? (commonClasses.disabledlink, commonClasses.opacity50) : null)}
          aria-label='breadcrumb'
        >
          {loadingPage
            ? <NavLink to={`/sites/${siteNameFormatter(site.name)}`} onClick={(event) => event.preventDefault()}>{site.name ? site.name : 'Site'} </NavLink>
            : <NavLink to={`/sites/${siteNameFormatter(site.name)}`}>{site.name ? site.name : 'Site'}</NavLink>}
          {loadingPage
            ? <NavLink activeStyle={{ fontWeight: 'bold', textDecoration: 'underline' }} to={`/sites/${siteNameFormatter(site.name)}/registries`} onClick={(event) => event.preventDefault()}>Registries</NavLink>
            : <NavLink activeStyle={{ fontWeight: 'bold', textDecoration: 'underline' }} to={`/sites/${siteNameFormatter(site.name)}/registries`}>Registries</NavLink>}
        </Breadcrumbs>
      )}
      <Container maxWidth='xl'>
        {site._id && !adRootLoading
          ? (
            <Card>
              <CardHeader
                title={rocketeerSite.name}
                action={
                  <>
                    <Button
                      disabled={!canEditSite}
                      className={addClasses(
                        rocketeerSite.isActive
                          ? commonClasses.btnDanger
                          : commonClasses.btnSuccess,
                        commonClasses.mr_1
                      )}
                      variant='contained'
                      startIcon={
                      rocketeerSite.isActive
                        ? (
                          <LockIcon />
                          )
                        : (<LockOpenIcon />)
                    }
                      onClick={toggleSiteActivation}
                    >
                      {rocketeerSite.isActive ? 'Deactivate' : 'Activate'}
                    </Button>
                    <NavLink
                      to={`/sites/${siteNameFormatter(site.name)}/history`}
                    >
                      <Button
                        className={addClasses(
                          commonClasses.btnInfo,
                          commonClasses.mr_1
                        )}
                        variant='contained'
                        title='View History'
                        startIcon={<HistoryIcon />}
                      >
                        View history
                      </Button>
                    </NavLink>
                    <Button
                      disabled={!(isAdFuelVersionSelected && canEditSite)}
                      className={addClasses(
                        commonClasses.btnSuccess,
                        commonClasses.mr_1
                      )}
                      variant='contained'
                      startIcon={<CloudUploadIcon />}
                      onClick={deployRegistries}
                    >
                      Deploy
                    </Button>
                  </>
              }
              />
              <Divider />
              <CardContent className={commonClasses.cardBkClr}>
                <Grid container spacing={2}>
                  <Grid item md={12} lg={6}>
                    <Box mb={2}>
                      <GeneralInformation
                        data={rocketeerSite}
                        canEditSite={canEditSite}
                        setImplementationMethod={setImplementationMethod}
                      />
                    </Box>
                    <Box mb={2}>
                      <Categories
                        data={rocketeerSite}
                        canEditSite={canEditSite}
                      />
                    </Box>
                    <Responsiveness
                      data={rocketeerSite}
                      canEditSite={canEditSite}
                    />
                  </Grid>
                  <Grid item md={12} lg={6}>
                    <Box mb={2}>
                      <AdFuelConfiguration
                        data={rocketeerSite}
                        canEditSite={canEditSite}
                      />
                    </Box>
                    {/* {implementationMethod === 'NPM'
                      ? (
                        <NPMConfiguration
                          data={rocketeerSite}
                          canEditSite={canEditSite}
                        />
                        )
                      : ( */}
                        <AISConfiguration
                          data={rocketeerSite}
                          canEditSite={canEditSite}
                        />
                      {/* )} */}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            )
          : (
            <Card>
              <CardContent>
                <CircularProgress />
                <List>
                  <ListItem>{workingText}</ListItem>
                </List>
              </CardContent>
            </Card>
            )}
      </Container>
      <CustomDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        title={dialog.title}
        subtitle={dialog.subtitle}
        content={dialog.content}
        displayDialogAction={dialog.displayDialogAction}
        customAction={dialog.customAction}
        size={dialog.size}
      />
      <Toast
        isOpen={toast.isOpen}
        variant={toast.variant}
        message={toast.message}
        onClose={handleCloseMessage}
      />
    </>
  )
}
